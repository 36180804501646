<template>
    <div class="w-full masonry">
        <CoolLightBox 
            :items="items" 
            :index="index"
            @close="index = null">
        </CoolLightBox>
        
        <div class="images-wrapper w-full">
            <masonry
                :cols="{default: 3, 700: 2, 480: 1}"
                :gutter="20"
            >
                <div
                    class="image"
                    v-for="(image, imageIndex) in items"
                    :key="imageIndex"
                    @click="index = imageIndex"
                >
                    <img :src="image">
                </div>
            </masonry>
        </div>
    </div>
</template>

<script>
    import CoolLightBox from 'vue-cool-lightbox'
    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

    export default {
        name: 'GalleryShowcase',
        props: {
            type: {
                type: String,
            }
        },
        components: {
            CoolLightBox,
        },
        data () {
            return {
                items: [],
                index: null,
            }
        },
        mounted () {
            let service = this
            
            switch (service.type) {
                case 'branding':
                    this.import(require.context('@/assets/img/showcase/branding/', true, /\.jpg/))
                    break
                case 'design:artwork':
                    this.import(require.context('@/assets/img/showcase/design/artwork/', true, /\.jpg/))
                    break
                case 'design:exhibition-graphics':
                    this.import(require.context('@/assets/img/showcase/design/exhibition-graphics/', true, /\.jpg/))
                    break
                case 'design:infographics':
                    this.import(require.context('@/assets/img/showcase/design/infographics/', true, /\.jpg/))
                    break
                case 'design:product-design':
                    this.import(require.context('@/assets/img/showcase/design/product-design/', true, /\.jpg/))
                    break
                case 'design:stationery':
                    this.import(require.context('@/assets/img/showcase/design/stationery/', true, /\.jpg/))
                    break
                case 'printing:signage':
                    this.import(require.context('@/assets/img/showcase/printing/signage/', true, /\.jpg/))
                    break
                case 'printing:brochures':
                    this.import(require.context('@/assets/img/showcase/printing/brochures/', true, /\.jpg/))
                    break
                case 'printing:business-cards':
                    this.import(require.context('@/assets/img/showcase/printing/business-cards/', true, /\.jpg/))
                    break
                case 'printing:banners':
                    this.import(require.context('@/assets/img/showcase/printing/banners/', true, /\.jpg/))
                    break
                case 'printing:cards-invites':
                    this.import(require.context('@/assets/img/showcase/printing/cards-invites/', true, /\.jpg/))
                    break
                default:
                    throw Error('Invalid gallery type ' + service.type)
            }
        },
        methods: {
            import(r) {
                r.keys().forEach(key => (
                    this.items.push(r(key))
                ))
            },
        },
    }
</script>