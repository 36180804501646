<template>
    <div>
        <div class="content pt-32 pb-12 lg:pb-12">
            <div class="container mx-auto flex flex-col lg:flex-row px-6">
                <div class="content-image lg:w-1/2 text-gray-700 flex flex-col-reverse lg:flex-row" :class="name">
                    <div class="image top bg-black">
                        <img :src="service.content.images.top">
                    </div>
                    <div class="image bottom">
                        <img :src="service.content.images.bottom">
                    </div>
                </div>
                <div class="lg:w-1/2 flex justify-start items-center">
                    <div class="xl:pl-16 lg:pr-0 text-gray-700">
                        <h1 class="text-3xl xl:text-4xl font font-heading mb-2 font-bold">
                            {{ service.content.title }}<span class="font-mono text-red-600">.</span>
                        </h1>

                        <p v-if="service.content.intro" class="font-condensed leading-relaxed mb-4 text-lg">{{ service.content.intro }}</p>

                        <p 
                            class="font-body mb-4 font-light leading-relaxed"
                            v-html="service.content.content"
                        ></p>

                        <p 
                            class="font-body text-sm mb-4 text-gray-600 leading-relaxed"
                            v-html="service.content.outro"
                        ></p>
                    </div>
                </div>
            </div>
        </div>
        
        <div :class="name" class="service-showcase  pt-12 pb-10 lg:py-12">
            <div class="container mx-auto flex flex-col px-6">
                <div class="w-full mb-6">
                    <h2 class="uppercase text-red-700 font-bold text-xs font-heading">{{ service.showcase.subtitle }}</h2>
                    <h3 class="text-gray-300 text-2xl lg:text-3xl font-heading font-bold lg:mb-6">{{ service.showcase.title }}<span class="font-mono text-red-700">.</span></h3>
                </div>
                <div class="w-full flex justify-center">
                    <div v-show="name == 'branding'">
                        <Gallery type="branding"/>
                    </div>

                    <vue-tabs 
                        direction="vertical"
                        v-show="name == 'design'"
                    >
                        <v-tab title="Stationery">
                            <Gallery type="design:stationery"/>
                        </v-tab>
                        <v-tab title="Artwork">
                            <Gallery type="design:artwork"/>
                        </v-tab>
                        <v-tab title="Product Design">
                            <Gallery type="design:product-design"/>
                        </v-tab>
                        <v-tab title="Exhibition Graphics">
                            <Gallery type="design:exhibition-graphics"/>
                        </v-tab>
                        <v-tab title="Infographics">
                            <Gallery type="design:infographics"/>
                        </v-tab>
                    </vue-tabs>

                    <vue-tabs 
                        class="w-full"
                        direction="vertical"
                        v-show="name == 'printing'"
                    >
                        <v-tab title="Signage">
                            <Gallery type="printing:signage"/>
                        </v-tab>
                        <v-tab title="Brochures, Leaflets &amp; Pamphlets">
                            <Gallery type="printing:brochures"/>
                        </v-tab>
                        <v-tab title="Business Cards &amp; Stationery">
                            <Gallery type="printing:business-cards"/>
                        </v-tab>
                        <v-tab title="Event Displays &amp; Roller Banners">
                            <Gallery type="printing:banners"/>
                        </v-tab>
                        <v-tab title="Cards &amp; Invites">
                            <Gallery type="printing:cards-invites"/>
                        </v-tab>
                    </vue-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Gallery from '@/components/content/gallery/GalleryShowcase'
    import {VueTabs, VTab} from 'vue-nav-tabs'

    export default {
        name: 'ContentService',
        props: {
            name: {
                type: String,
            }
        },
        components: {
            Gallery,
            VueTabs,
            VTab
        },
        computed: {
            service () {
                switch (this.name) {
                    case 'branding':
                        return {
                            content: {
                                title: 'Branding',
                                intro: 'We know that creating an identity for a new idea or business can be as daunting as it is exciting. That\'s why we\'re here to help every step of the way.',
                                content: 'We take the time to get to know you and your business. We understand that your brand encapsulates everything from your vision to your character and values. That\'s why we carefully and deliberately craft a visual identity that reflects the very best of you and your business.',
                                outro: '<span class="font-semibold">Looking to develop your brand with some inspiring design</span>? <span class="font-light">We can help with that <a href="/design" class="underline">too</a></span>.',
                                images: {
                                    top: require('@/assets/img/content/branding/1.jpg'),
                                    bottom: require('@/assets/img/content/branding/3.jpg'),
                                },
                            },
                            showcase: {
                                subtitle: 'Crafting an identity',
                                title: 'Whatever your brand, we\'ve got you covered',
                            }
                        }
                    case 'design':
                        return {
                            content: {
                                title: 'Design',
                                intro: 'Design is everywhere. Every day we are surrounded by posters, magazines, signs and advertisements, so how do you cut through the noise and get your message heard?',
                                content: 'At iNQUiSiTiON we know that it can be tough to get yourself noticed when there are so many things competing for our attention. For years we have been elevating our clients\' designs so that they stand out amongst the rest. Whether it\'s an unique album cover or a striking design for a new product, whatever the design, we\'ll get you noticed.',
                                outro: '<span class="font-semibold">Still developing your brand</span>? <span class="font-light">We can help with that <a href="/branding" class="underline">too</router-link></a>.',
                                images: {
                                    top: require('@/assets/img/content/design/4.jpg'),
                                    bottom: require('@/assets/img/content/design/3.jpg'),
                                },
                            },
                            showcase: {
                                subtitle: 'The language of visual communication',
                                title: 'Whatever your inspiration, we can help you realise it',
                            }
                        }
                    case 'printing':
                        return {
                            content: {
                                title: 'Printing',
                                intro: 'Need some new business cards? Could your office stationery use a refresh? How about signage, banners or diplays for your next big event?',
                                content: 'Whether it\'s a handful of flyers for your next gig or roller banners for your next exhibition, we print it all. With a staggering variety of sizes and finishes, we can print whatever you can think.',
                                outro: '<span class="font-semibold">Don\'t have a design of your own</span>? <span class="font-light">We can help with that <a href="/design" class="underline">too</router-link></a>.',
                                images: {
                                    top: require('@/assets/img/content/printing/1.jpg'),
                                    bottom: require('@/assets/img/content/printing/3.jpg'),
                                },
                            },
                            showcase: {
                                subtitle: 'If you can think it, we can print it',
                                title: 'We can bring your idea to life',
                            }
                        }
                    default:
                        throw new Error(this.name + ' is invalid.')
                }
            }
        }
    }
</script>

<style scoped>
    .content-image {
        @apply mb-4;
        position: relative;
        height: 15rem;
    }
    .content-image .image {
        @apply shadow-2xl;
        position: absolute;
    }
    .content-image .top {
        top: 0;
        left: 3rem;
    }
    .content-image .top:after {
        @apply bg-gray-800 bg-opacity-65;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 0;
    }
    .content-image .bottom img,
    .content-image .top img {
        max-width: 275px;
    }
    .content-image .bottom {
        right: 3rem;
        bottom: 0;
    }
    .content-image .bottom:after {
        @apply bg-red-800 bg-opacity-65;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 5;
    }
    .content-image .bottom img {
        max-height: 300px;
    }

    @media (min-width: 640px) { 
        .content-image {
            position: relative;
            height: 22.5rem;
        }
        .content-image .bottom img,
        .content-image .top img {
            max-width: 425px;
        }
    }
    @media (min-width: 1024px) {
        .content-image {
            height: 20rem;
        }

        .content-image .bottom img,
        .content-image .top img {
            max-width: 300px;
        }
    }
    @media (min-width: 1280px) { 
        .content-image {
            position: relative;
            height: 25rem;
        }
        .content-image .bottom img,
        .content-image .top img {
            max-width: 475px;
        }
    }

    .service-showcase {
		@apply bg-gray-900 bg-opacity-90;
    }
</style>